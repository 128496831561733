import { createStore } from 'vuex'
import PersonaNatural from '@/apps/pharmasan/juridica/procesosJuridicos/services/personaNatural.service'
const _PersonaNatural = new PersonaNatural()
const StorePersonas = {
  state: {
    _personas: [],
    _persona: {},
    _searchPersona: {}
  },
  getters: {
    personas: state => state._personas,
    persona: state => state._persona,
    getSearchPersona: state => state._searchPersona
  },
  mutations: {
    setPersonas (state, payload) {
      state._personas = payload
    },
    setPersona (state, payload) {
      state._persona = payload
    },
    setUpdatePersona (state, payload) {
      state._persona.fullname = payload.fullname
      state._persona.docTipoId = payload.docTipoId
      state._persona.numeroIdentificacion = payload.numeroIdentificacion
      state._persona.telefono = payload.telefono
      state._persona.correo = payload.correo
      state._persona.departamentoId = payload.departamentoId
      state._persona.municipioId = payload.municipioId
      state._persona.representanteLegal = payload.representanteLegal
      state._persona.direccion = payload.direccion
    },
    setDefault (state) {
      state._persona = {}
    },
    setSearchPersona (state, payload) {
      state._searchPersona = payload
    }
  },
  actions: {
    getAll ({ commit }, payload) {
      _PersonaNatural.search(payload).then(({ data }) => {
        commit('setPersonas', data)
      })
    },
    createPersona ({ commit }, payload) {
      return _PersonaNatural.create(payload).then((response) => {
        return response
      })
    },
    removePersona ({ commit }, id) {
      return _PersonaNatural.remove(id).then((response) => {
        return response
      })
    },
    findPersonaById ({ commit }, id) {
      return new Promise((resolve, reject) => {
        _PersonaNatural.findPersonaById(id).then(({ data }) => {
          commit('setPersona', data)
          resolve(data)
        })
      })
    },
    updatePersona ({ getters }) {
      const id = getters.persona.id
      return new Promise((resolve, reject) => {
        _PersonaNatural.updatePersona(getters.persona, id).then(({ data }) => {
          resolve(data)
        })
      })
    },
    searchPersona ({ commit }, payload) {
      return _PersonaNatural.searchByDocument(payload).then(({ data }) => {
        commit('setSearchPersona', data)
        return data
      })
    }
  }
}
const store = createStore(StorePersonas)
export default {
  namespaced: true,
  ...store
}
