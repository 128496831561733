import http from '@/libs/http'

const baseUrl = process.env.VUE_APP_MICROSERVICE_API_PROCESOS_JURIDICOS
export default class PersonaNaturalService {
  search (payload) {
    return http.get(`${baseUrl}/personas-naturales/paginate`, {
      params: {
        ...payload.paginator,
        filters: payload.filters
      },
      headers: {
        loading: true
      }
    })
  }

  searchByDocument (params) {
    return http.get(`${baseUrl}/personas-naturales/search`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  create (payload) {
    return http.post(`${baseUrl}/personas-naturales`, payload, {
      headers: {
        loading: true
      }
     })
  }

  remove (id) {
    return http.delete(`${baseUrl}/personas-naturales/${id}`, {
      headers: {
        loading: true
      }
     })
  }

  findPersonaById (id) {
    return http.get(`${baseUrl}/personas-naturales/${id}`, {
      headers: {
        loading: true
      }
     })
  }

  updatePersona (data, id) {
    return http.put(`${baseUrl}/personas-naturales/${id}`, data, {
      headers: {
        loading: true
      }
     })
  }
}
