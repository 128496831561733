import { createStore } from 'vuex'
import DocTiposService from '../services/docTipo.service'

const _DocTiposService = new DocTiposService()

const DocTiposStore = {
  state: {
    listDocTipos: []
  },
  getters: {
    getListDocTipos: (state) => state.listDocTipos
  },
  mutations: {
    setListDocTipos (state, payload) {
      state.listDocTipos = payload
    }
  },
  actions: {
    search ({ commit }) {
      return _DocTiposService.getAll().then(({ data }) => {
        commit('setListDocTipos', data)
        return data
      })
    }
  }
}
const store = createStore(DocTiposStore)
export default {
  namespaced: true,
  ...store
}
