<template>
  <div class="flex justify-center">
    <div class="box p-4 flex flex-col gap-4 w-2/4">
      <section id="headerInfo" class="flex justify-center">
        <span class="font-bold text-2xl">
          Agregar Demandado
        </span>
      </section>

      <section id="bodyInfo" class="flex flex-col items-center gap-2 w-full">
        <div class="flex w-full gap-4">
          <div class="flex flex-col w-1/4">
            <span class="text-xs">Tipo de Documento:</span>
            <Dropdown v-model="model.docTipoId" :options="listDocTipos" optionLabel="nombre" optionValue="code" :filter="true"/>
            <MessageError :text="errors.docTipoId" />
          </div>
          <div class="flex flex-col w-2/4">
            <span class="text-xs">Numero de Documento:</span>
            <InputText type="text" v-model="model.numeroIdentificacion" :disabled="!model.docTipoId" @change="searchDemandante()"/>
            <MessageError :text="errors.numeroIdentificacion" />
          </div>
          <div class="flex flex-col w-1/4">
            <span class="text-xs">Tipo de Persona:</span>
            <Dropdown v-model="model.personaJuridica" :options="listPerTipos" optionLabel="nombre" optionValue="value"/>
            <MessageError :text="errors.docTipoId" />
          </div>
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Nombre Completo:</span>
          <InputText type="text" v-model="model.fullname"/>
          <MessageError :text="errors.fullname" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Numero de Teléfono:</span>
          <InputText type="text" v-model="model.telefono"/>
          <MessageError :text="errors.telefono" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Correo Electronico:</span>
          <InputText type="text" v-model="model.correo"/>
          <MessageError :text="errors.correo" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Departamento:</span>
          <Dropdown v-model="model.departamentoId" :options="departamentos" optionLabel="nomDpto" optionValue="codDpto" :filter="true" @change="onChangeDepartamentos($event)"/>
          <MessageError :text="errors.departamentoId" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Municipio:</span>
          <Dropdown v-model="model.municipioId" :options="municipios" optionLabel="nomMpio" optionValue="codMpio" :filter="true"/>
          <MessageError :text="errors.municipioId" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Dirección de Residencia:</span>
          <InputText type="text" v-model="model.direccion"/>
          <MessageError :text="errors.direccion" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Nombre del Representante Legal (opcional):</span>
          <InputText type="text" v-model="model.representanteLegal"/>
          <MessageError :text="errors.representanteLegal" />
        </div>
        <div class="flex flex-col  w-full">
          <span class="text-xs">Numero de Contacto (opcional):</span>
          <InputText type="text" v-model="model.representanteLegalContacto"/>
          <MessageError :text="errors.representanteLegalContacto" />
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Nombre Apoderado:</span>
          <InputText type="text" v-model="model.apoderadoNombre"/>
          <MessageError :text="errors.apoderadoNombre" />
        </div>
        <div class="flex w-full gap-4">
          <div class="flex flex-col w-1/4">
            <span class="text-xs">Tipo Doc.:</span>
            <Dropdown v-model="model.apoderadoTipoDoc" :options="listDocTipos" optionLabel="nombre" optionValue="code" :filter="true"/>
            <MessageError :text="errors.apoderadoTipoDoc" />
          </div>
          <div class="flex flex-col w-3/4">
            <span class="text-xs">Documento:</span>
            <InputText type="text" v-model="model.apoderadoDocumento"/>
            <MessageError :text="errors.apoderadoDocumento" />
          </div>
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Tarjeta Profesional:</span>
          <InputText type="text" v-model="model.apoderadoTarjeta"/>
          <MessageError :text="errors.apoderadoTarjeta" />
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Correo:</span>
          <InputText type="text" v-model="model.apoderadoCorreo"/>
          <MessageError :text="errors.apoderadoCorreo" />
        </div>
        <div class="flex flex-col w-full">
          <span class="text-xs">Telefono:</span>
          <InputText type="text" v-model="model.apoderadoTelefono"/>
          <MessageError :text="errors.apoderadoTelefono" />
        </div>
      </section>

      <section id="footerInfo" class="flex justify-between gap-4">
        <div class="flex justify-between w-full">
          <Button label="Cancelar"
                  icon="pi pi-angle-left"
                  class="p-button-danger"
                  @click="$router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos' })"
          />
          <Button label="Agregar"
                  icon="pi pi-angle-right"
                  iconPos="right"
                  @click="add()"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import DocTiposStore from '../../../../../../store/docTipos.store'
import PersonaNaturalStore from '../../../../../../store/personaNatural.store'
import ProcesosJudicialesStore from '../../../../../../store/procesosJudiciales.store'
import LocalizacionStore from '../../../../../../store/localizacion.store'
import { onMounted, computed, ref } from 'vue'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import { useRouter, useRoute } from 'vue-router'

export default {
  name: 'buscar-accionado',
  setup () {
    const listDocTipos = computed(() => DocTiposStore.getters.getListDocTipos)
    const demandadoSearch = computed(() => PersonaNaturalStore.getters.getSearchPersona)
    const departamentos = computed(() => LocalizacionStore.getters._departamentos)
    const municipios = computed(() => LocalizacionStore.getters._municipios)
    const router = useRouter()
    const route = useRoute()

    const listPerTipos = ref([
      { nombre: 'Persona Natural', value: false },
      { nombre: 'Persona Juridica', value: true }
    ])

    const schema = yup.object({
      fullname: yup.string().required('El campo es requerido'),
      docTipoId: yup.string().required('El campo es requerido').label(''),
      numeroIdentificacion: yup.string().required('El campo es requerido').label(''),
      telefono: yup.string().required('El campo es requerido'),
      correo: yup.string().email().required('El campo es requerido'),
      municipioId: yup.string().required('El campo es requerido'),
      departamentoId: yup.string().required('El campo es requerido'),
      direccion: yup.string().required('El campo es requerido'),
      representanteLegal: yup.string().nullable(),
      representanteLegalContacto: yup.string().nullable(),
      personaJuridica: yup.boolean().required('El campo es requerido'),
      apoderadoNombre: yup.string().nullable(true),
      apoderadoTipoDoc: yup.string().nullable(true),
      apoderadoDocumento: yup.string().nullable(true),
      apoderadoTarjeta: yup.string().nullable(true),
      apoderadoCorreo: yup.string().nullable(true),
      apoderadoTelefono: yup.string().nullable(true)
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('fullname')
    useField('docTipoId')
    useField('numeroIdentificacion')
    useField('telefono')
    useField('correo')
    useField('municipioId')
    useField('departamentoId')
    useField('direccion')
    useField('representanteLegal')
    useField('representanteLegalContacto')
    useField('personaJuridica', null, { initialValue: false })
    useField('apoderadoNombre')
    useField('apoderadoTipoDoc')
    useField('apoderadoDocumento')
    useField('apoderadoTarjeta')
    useField('apoderadoCorreo')
    useField('apoderadoTelefono')

    const searchMunicipio = (codDpto) => {
      LocalizacionStore.dispatch('getMunicipios', codDpto)
    }

    const onChangeDepartamentos = (e) => {
      searchMunicipio(e.value)
      model.municipioId = ''
    }

    const searchDemandante = () => {
      if (model.docTipoId === '' || model.numeroIdentificacion === '') {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Debe ingresar el tipo de documento y el numero de documento'
        })
        return
      }
      PersonaNaturalStore.dispatch('searchPersona', {
        docTipoId: model.docTipoId,
        numeroIdentificacion: model.numeroIdentificacion
      }).then((res) => {
        if (res) {
          searchMunicipio(demandadoSearch.value.departamentoId)
          model.id = res.id
          model.fullname = demandadoSearch.value.fullname
          model.docTipoId = demandadoSearch.value.docTipoId
          model.numeroIdentificacion = demandadoSearch.value.numeroIdentificacion
          model.telefono = demandadoSearch.value.telefono
          model.correo = demandadoSearch.value.correo
          model.municipioId = demandadoSearch.value.municipioId
          model.departamentoId = demandadoSearch.value.departamentoId
          model.direccion = demandadoSearch.value.direccion
          model.representanteLegal = demandadoSearch.value.representanteLegal
          model.representanteLegalContacto = demandadoSearch.value.representanteLegalContacto
          model.personaJuridica = demandadoSearch.value.personaJuridica
          model.apoderadoNombre = demandadoSearch.value.apoderadoNombre
          model.apoderadoTipoDoc = demandadoSearch.value.apoderadoTipoDoc
          model.apoderadoDocumento = demandadoSearch.value.apoderadoDocumento
          model.apoderadoTarjeta = demandadoSearch.value.apoderadoTarjeta
          model.apoderadoCorreo = demandadoSearch.value.apoderadoCorreo
          model.apoderadoTelefono = demandadoSearch.value.apoderadoTelefono
        } else {
          model.id = ''
          model.fullname = ''
          model.telefono = ''
          model.correo = ''
          model.municipioId = ''
          model.departamentoId = ''
          model.direccion = ''
          model.representanteLegal = ''
          model.representanteLegalContacto = ''
          model.apoderadoNombre = ''
          model.apoderadoTipoDoc = ''
          model.apoderadoDocumento = ''
          model.apoderadoTarjeta = ''
          model.apoderadoCorreo = ''
          model.apoderadoTelefono = ''
          model.personaJuridica = false
        }
      })
    }

    const add = handleSubmit((values) => {
      const { id } = values
      delete values.id
      ProcesosJudicialesStore.dispatch('saveDemandanteDemandado', {
        accionadoId: id,
        accionado: values,
        procesoJudicialId: route.params.id
      }).then(() => {
        router.push({ name: 'pharmasan.administrativa.juridica.procesos-judiciales.ver.info-sujetos' })
      })
    })

    onMounted(() => {
      LocalizacionStore.dispatch('getDeparatamentos')
      DocTiposStore.dispatch('search')
    })

    return {
      listDocTipos,
      errors,
      model,
      add,
      departamentos,
      municipios,
      onChangeDepartamentos,
      searchDemandante,
      listPerTipos
    }
  }
}
</script>

<style scoped>

</style>
